export const FETCH_CURRENCIES_REQUEST = 'FETCH_CURRENCIES_REQUEST';
export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS';
export const FETCH_CURRENCIES_FAILURE = 'FETCH_CURRENCIES_FAILURE';
export const LOADING = 'LOADING';
export const SET_EXCHANGE = 'SET_EXCHANGE';
export const TOGGLE_EXCHANGE_METHOD = 'TOGGLE_EXCHANGE_METHOD';
export const UPDATE_COMPUTED_PRICE = 'UPDATE_COMPUTED_PRICE';
export const UPDATE_COMPUTED_CURRENCY = 'UPDATE_COMPUTED_CURRENCY';
export const SET_LAST_UPDATE = 'SET_LAST_UPDATE';
export const SET_THEME = 'SET_THEME';
export const SET_RATE_VALUE = 'SET_RATE_VALUE';
